<!--
 * @Description: 泊位全景 车行道 outCharge
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-08 14:32:31
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <van-nav-bar title="泊位全景"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row>
      <van-search v-model="queryForm.parkSpaceNumber"
                  show-action
                  placeholder="请输入泊位号">
        <template #action>
          <div @click="getAllBerthage">搜索</div>
        </template>
      </van-search>
    </van-row>
    <div class="tree-box"
         ref="wrapperBox">
      <div class="card"
           v-for="item in searchShow ? searchBerthageList : berthageList "
           :key="item.parkSpaceNumber"
           @click="handleClickPlateNumber(item)">
        <van-row class="parkSpaceId">
          <span :style="{color:item.titleColor}">{{item.parkSpaceNumber}}</span>
        </van-row>
        <van-row class="spaceLine"></van-row>
        <van-row v-if="item.entranceTime">
          <van-row class="carInfo">
            <van-row class="text">车牌号</van-row>
            <van-row class="value">{{item.plateNumber}}</van-row>
          </van-row>
          <van-row class="carInfo">
            <van-row class="text">车牌颜色</van-row>
            <van-row class="value">{{item.plateColor}}</van-row>
          </van-row>
          <van-row class="carInfo">
            <van-row class="text"
                     :style="{color:item.color}">置信度</van-row>
            <van-row class="value"
                     :style="{color:item.color}">{{item.plateScoreO}}</van-row>
          </van-row>
        </van-row>
        <van-row v-else>
          <van-row class="button">停车登记</van-row>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      timedTask: null, // 定时任务
      berthageList: [], // 全部泊位列表
      onlinelist: [], // 在线订单页面
      // 查询表单
      queryForm: {
        parkId: this.$cookie.get('managePark').manageParkId,
        parkLayerId: this.$cookie.get('manageParkLayer').manageParkLayerId,
        parkSpaceNumber: ''
      },
      fileList: [], // 图片列表
      list: [],
      searchBerthageList: [],
      searchShow: false,
      scrollTop: 0
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    'queryForm.parkSpaceNumber' (val) {
      if (val.length === 0) {
        this.getAllBerthage()
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getAllBerthage()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.timedTask = setInterval(() => {
      this.getAllBerthage()
    }, 180000)
    // 获取滑动位置
    this.$refs.wrapperBox.addEventListener('scroll', () => {
      this.scrollTop = this.$refs.wrapperBox.scrollTop
    }, false)
  },
  updated () {
    // 获取当前上次打开的位置
    this.$nextTick(() => {
      this.$refs.wrapperBox.scrollTop = Number(localStorage.getItem('indexscTop'))
    })
  },
  beforeDestroy () {
    // 页面销毁时设置位置
    localStorage.setItem('indexscTop', this.scrollTop)
  },
  destroyed () {
    if (this.timedTask) {
      clearTimeout(this.timedTask)
      this.timedTask = null
    }
  }, // 生命周期 - 销毁完成
  // 方法集合
  methods: {
    // 获取全部泊位
    getAllBerthage () {
      if (this.queryForm.parkSpaceNumber) {
        // 搜索泊位
        this.searchShow = true
        this.berthageInput(this.queryForm.parkSpaceNumber)
      } else {
        // 默认展示
        this.searchShow = false
        const info = {
          columnName: ['park_space_id', 'park_space_id'],
          tableName: 'tb_park_space',
          whereStr: [
            {
              colName: 'park_id',
              value: this.$cookie.get('managePark').manageParkId
            },
            {
              colName: 'park_layer_id',
              value: this.$cookie.get('manageParkLayer').manageParkLayerId
            }
          ]
        }
        this.$queryDict.queryDict(info).then(res => {
          res.resultEntity.forEach(item => {
            item.parkSpaceNumber = item.code
          })
          this.berthageList = res.resultEntity
          this.queryOnlineOrder() // 获取订单
        })
      }
    },
    // 泊位选择器筛选
    berthageInput (val) {
      this.searchBerthageList = this.berthageList.filter((item) => {
        return item.parkSpaceNumber.toLowerCase().includes(val.toLowerCase())
      })
    },
    // 获取订单
    queryOnlineOrder () {
      const info = {
        parkId: this.$cookie.get('managePark').manageParkId,
        parkLayerId: this.$cookie.get('manageParkLayer').manageParkLayerId,
        parkSpaceNumber: this.queryForm.parkSpaceNumber
      }
      this.$outCharge.getOrderOnlineNoPage(info).then(response => {
        this.onlinelist = response.resultEntity
        const that = this
        for (let j = 0; j < that.onlinelist.length; j++) {
          // 置信度颜色
          that.onlinelist[j].plateScoreO = parseInt(that.onlinelist[j].plateScore * 100)
          if (that.onlinelist[j].plateScoreO >= 90) {
            that.onlinelist[j].color = '#069F47'
          } else if (that.onlinelist[j].plateScoreO >= 80) {
            that.onlinelist[j].color = '#FEB200'
          } else {
            that.onlinelist[j].color = '#D50101'
          }

          // 设置已支付泊位的颜色
          if (that.onlinelist[j].orderStatusCode === 2) {
            that.onlinelist[j].titleColor = 'red'
          }
          if (that.onlinelist[j].lastPaymentTime !== undefined && that.onlinelist[j].lastPaymentTime !== '' && that.onlinelist[j].lastPaymentTime !== null) {
            that.onlinelist[j].titleColor = 'red'
          }
        }

        for (let j = 0; j < that.onlinelist.length; j++) {
          for (let i = 0; i < that.berthageList.length; i++) {
            if (that.berthageList[i].parkSpaceNumber === that.onlinelist[j].parkSpaceNumber) {
              that.berthageList[i] = that.onlinelist[j]
            }
          }
        }

        // 把已支付 有缴费时间不为'' 的放到最前面
        const lastPayList = that.berthageList.filter(item => {
          return item.orderStatusCode === 2 || (item.lastPaymentTime !== undefined && item.lastPaymentTime !== null && item.lastPaymentTime !== '')
        })
        const leftList = that.berthageList.filter(item => {
          return !lastPayList.includes(item)
        })

        // const lastPayList = that.berthageList.filter(item => {
        //   return item.orderStatusCode === 2
        // })
        // const leftList = that.berthageList.filter(item => {
        //   return item.orderStatusCode !== 2
        // })
        that.berthageList = [...lastPayList, ...leftList]
      })
    },
    // 点击车牌号
    handleClickPlateNumber (item) {
      if (item.entranceTime) {
        this.$router.push({
          name: 'orderDetails',
          query: {
            orderDetails: JSON.stringify(item),
            parkTypeCode: this.$route.query.parkTypeCode
          }
        })
      } else {
        this.$router.push({
          name: 'register',
          query: {
            parkSpaceNumber: item.parkSpaceNumber,
            page: 'out'
          }
        })
      }
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.push('/homePage')
    }
  }
}
</script>
<style lang='less' scoped>
// @import url(); 引入公共css类
.mainBody {
  height: 100%;
  width: 100%;
  user-select: none;
  .tree-box {
    height: calc(100vh - @navHeight - 54px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow: auto;
  }
  .card {
    width: 45%;
    margin-top: 12px;
    padding-bottom: 10px;
    border-radius: 5px;
    background-color: #fff;
    > .van-row {
      width: 90%;
      margin: 0 auto;
    }
  }
  .parkSpaceId {
    width: 100%;
    height: 39.5px;
    text-align: center;
    line-height: 39.5px;
    font-family: PingFang-SC-Bold;
    font-weight: 600;
    font-size: 17px;
  }
  .spaceLine {
    height: 0.5px;
    background-color: #e4e4e4;
  }
  .carInfo {
    width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 0.9735rem;
    margin-top: 5px;
    display: flex;
    position: relative;
    .text {
      color: #999999;
      font-family: PingFang-SC-Regular;
      z-index: 99;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .value {
      background: #ffffff;
      color: #333333;
      font-family: PingFang-SC-Medium;
      display: block;
      word-break: keep-all;
      z-index: 100;
      position: absolute;
      right: 0;
    }
  }
  .button {
    color: #1989fa;
    height: 30px;
    width: 50%;
    text-align: center;
    margin: 30px auto;
  }
}
</style>
